import { Link } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"

const Shelters = () => {
  return (
    <Layout>
      <div className="shelters__picture" />
      <section className="claimants__detail">
        <h1 className="claimants__detail-title">
          Shelters for Refugee Claimants
        </h1>
        <p>
          Refugee claimants differ from sponsored refugees in that they often
          have no one to meet them, no housing in place, and few resources to
          support them.
        </p>
        <p>
          While trying to meet basic needs and survive, refugee claimants are
          also going through the complex refugee protection process leading to
          their refugee hearing, where a decision is made whether they can stay
          in Canada or not. It can take months, and sometimes years to reach
          this point. The whole process from arriving in Canada to receiving
          Permanent Residence can take 2-3 years, and sometimes even longer.
        </p>
        <p>
          One of the most pressing needs for a newly arrived refugee claimant is
          finding a place to stay. When resettled refugees (Privately Sponsored
          Refugees and Government Assisted Refugees) arrive in Canada, they
          immediately receive Permanent Resident status and have housing and
          settlement support available to them. Refugee claimants do not have
          this kind of support.
        </p>
        <p>
          There are some short-term refugee housing options that have been
          established specifically for refugee claimants. These are a good
          option and offer much more support and safety than other homeless
          shelters. Not only do they provide a safe place to live at no cost,
          they also offer other specialized supports that refugee claimants need
          during their first few months in Canada. Many, but not all, of these
          refugee shelters in Canada were established by Christians and have
          strong connections to churches.
        </p>
        <p>
          <strong>
            If you are a refugee claimant looking for a place to stay, you can
            contact any refugee shelters directly to see if they have space.
            Click{" "}
            <a
              href="https://drive.google.com/file/d/16H25lbK4VRc6CJ4bAN5BaYmAQnUE5jQf/view"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            for the list of shelters across Canada.
          </strong>{" "}
          You can also use the <Link to="/map/">Resource Map</Link> to look up
          refugee houses near you.
        </p>
        <p>
          <strong>
            If you are in Toronto, you can also contact{" "}
            <a
              href="https://www.redcross.ca/how-we-help/migrant-and-refugee-services/first-contact/first-contact-ontario"
              target="_blank"
              rel="noreferrer"
            >
              Red Cross First Contact
            </a>{" "}
            and they will assist you in finding a place to stay.
          </strong>
        </p>
        <p>
          Toll-Free: 1-866-902-4993
          <br />
          Phone: 416-480-2500 options 1 & 1
          <br />
          Email: firstcontactontario@redcross.ca
        </p>
        <p>
          You can watch the videos to learn more about refugee houses and listen
          to some real-life sharing from residents in the shelter.
        </p>
        <div className="claimants__video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4ro1X6gzm64Bcon1MzEuQZPR"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </section>
    </Layout>
  )
}

export default Shelters
